import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Joi from 'joi';

const outerVariants = {
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
  open: {
    height: 400,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const signupSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

const SignUp = ({ open }) => {
  const [signupError, setSignupError] = React.useState({
    error: false,
    message: '',
  });

  const mailchimpSignUp = async (email) => {
    const { error, value } = signupSchema.validate({ email });
    if (error) {
      setSignupError({
        error: true,
        message: `${value} is not a valid email address`,
      });
      return;
    }
    try {
      const signedUp = await axios.post(
        process.env.REACT_APP_MAILCHIMP_URL,
        {
          email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_MAILCHIMP_API_KEY}`,
          },
        }
      );
      return signedUp;
    } catch (error) {
      setSignupError(true);
      console.log(error);
    }
  };

  const [email, setEmail] = React.useState('');

  return (
    <motion.div
      initial={{
        height: 0,
        opacity: 0,
      }}
      animate={{
        height: 400,
        opacity: 1,
        transition: {
          duration: 1,
        },
      }}
      exit={{
        height: 0,
        opacity: 0,
        transition: {
          duration: 1,
        },
      }}
      className='w-full fixed top-[64px] bg-black/40 z-10 p-[20px] overflow-visible backdrop-blur-md'
    >
      <div className='h-full w-full'>
        <motion.div
          initial={{
            height: 0,
            opacity: 0,
          }}
          animate={{
            height: '100%',
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              duration: 1,
            },
          }}
          className='flex flex-col gap-10 justify-center items-center max-w-[600px] bg-black/40 mx-auto rounded-lg px-5'
        >
          <p className='text-white sectionTitle'>Sign up for our newsletter</p>
          <input
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='rounded-lg paragraph border-none h-[40px] focus:outline-none px-3'
          />
          {signupError.error && (
            <p className='text-red-500 paragraph'>{signupError.message}</p>
          )}
          <div className='flex  justify-center items-center '>
            <motion.button
              onClick={() => mailchimpSignUp(email)}
              whileTap={{
                scale: 0.9,
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                transition: { duration: 0.2, ease: 'easeIn' },
              }}
              className='paragraph bg-white text-offBlack rounded-md border-none w-full px-[10px] h-[40px]'
            >
              Sign Up
            </motion.button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default SignUp;
